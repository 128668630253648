import { useRouteResolver } from '@experiences/util';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import * as RouteNames from '../../common/constants/RouteNames';
import { useOrganizationName } from '../../common/hooks/useOrganizationName';
import { isAdminSelector } from '../../store/selectors';
import { UiDropdownButton } from '../common/UiDropdownButton/UiDropdownButton';
import { UiHostDeprecationBanner } from '../common/UiHostDeprecationBanner/UiHostDeprecationBanner';
import UiPageContainer from '../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from '../organizationsettings/AdminBreadCrumbs';

const useStyles = makeStyles(theme =>
    createStyles({
        unlicensedContainer: {
            background: theme.palette.semantic.colorBackground,
            flexDirection: 'column',
            flexGrow: 'inherit',
            display: 'flex',
        },
        unlicensedOptionsContainer: {
            display: 'flex',
            flexGrow: 'inherit',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: '20%',
        },
        centered: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        headerText: {
            fontWeight: 600,
            fontSize: '18px',
            marginBottom: '12px',
        },
        regularText: { marginBottom: '12px' },
        activateAction: { marginBottom: '12px' },
    }),
);

export const Unlicensed = () => {

    const { formatMessage: translate } = useIntl();

    const isAdmin = useSelector(isAdminSelector);

    const history = useHistory();
    const getRoute = useRouteResolver();
    const classes = useStyles();

    return (
        <div className={classes.unlicensedContainer}>
            <div
                className={classes.unlicensedOptionsContainer}
                data-testid='unlicensed-container'
                data-cy='unlicensed-container'>
                <div className={classes.centered}>
                    <Typography
                        className={classes.headerText}
                        data-testid='unlicensed-header'>
                        {translate({ id: 'CLIENT_NO_LICENSE_AVAILABLE' })}
                    </Typography>
                    <Typography
                        className={classes.regularText}
                        data-testid='unlicensed-text'>
                        {translate({ id: 'CLIENT_ACTIVATE_LICENSE_GET_STARTED' })}
                    </Typography>
                    <div>
                        <UiDropdownButton
                            label={translate({ id: 'CLIENT_ACTIVATE_LICENSE' })}
                            disabled={!isAdmin}
                            tooltip={isAdmin ? undefined : translate({ id: 'CLIENT_ACTIVATE_LICENSE_ADMINS_ONLY' })}
                            menuItems={[
                                {
                                    onClick: () => {
                                        history.push(`${getRoute(RouteNames.LicensingActivateLicenseOnline)}`);
                                    },
                                    label: translate({ id: 'CLIENT_ONLINE_ACTIVATION' }),
                                },
                                {
                                    onClick: () => {
                                        history.push(`${getRoute(RouteNames.ActivateOffline)}`);
                                    },
                                    label: translate({ id: 'CLIENT_OFFLINE_ACTIVATION' }),
                                },
                            ]}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export const UnlicensedComponent = () => {
    const { formatMessage: translate } = useIntl();
    const organizationName = useOrganizationName();
    const breadCrumbLinks = [
        {
            link: RouteNames.OrganizationAdminHome,
            name: organizationName,
        },
        {
            link: RouteNames.Licensing,
            name: translate({ id: 'CLIENT_LICENSES' }),
        },
    ];
    return <UiPageContainer
        header={AdminBreadCrumbs(breadCrumbLinks)}
        banner={
            <UiHostDeprecationBanner />
        }
        position='left'>
        <Unlicensed />
    </UiPageContainer>;
};
