export const AboutAccountsLink = '/admin-guide/about-accounts';

export const GroupsAndRolesLink = '/admin-guide/about-accounts';

export const TenantsAndServicesLink = '/admin-guide/managing-tenants-and-services#managing-services';

export const EnablingDisablingServicesLink = '/admin-guide/managing-tenants-and-services#enabling-or-disabling-services';

export const AboutLicensingLink = '/admin-guide/about-licensing';

export const LicensingRobotServicesLink = '/admin-guide/about-licensing';

export const PortalTagManagementOrganizingResourcesLink = '/admin-guide/managing-tags';

// Need to clean up Cloud only links below
export const ManagingUsersBulkAddingLink = '/admin-guide/managing-user-accounts#adding-users-in-bulk';

export const TenantRegionDocsLinks = '/admin-guide/data-residency-cloud';

export const LeavingOrganizationLink = '/admin-guide/managing-user-preferences#leaving-an-organization';

export const LicensingManagementOptionsLink = '/overview/license-management-options';

export const LegacyLicenseManagementLink = '/license-management-options#legacy-license-management';

export const IPRestrictionLink = '/admin-guide/restricting-access-by-ip';

export const IPRestrictionImportCsvLink = '/admin-guide/restricting-access-by-ip#csv-import';

export const SessionPolicyLink = '/admin-guide/session-policy';

export const EncryptionLink = '/admin-guide/encryption';

export const CustomerManagedKeyBestPracticesLink = '/admin-guide/customer-managed-key#best-practices-for-using-customer-managed-keys';

export const CustomerManagedKeySwitchLink = '/admin-guide/switch-from-customer-managed-to-uipath-managed-keys';

export const DirectorySSOLink = '/admin-guide/configuring-authentication-and-security-organization-level#models';
