import { useModalState } from '@experiences/util';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import {
    PortalAlertBar,
    PortalPeoplePicker,
} from '@uipath/portal-shell-react';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

import useUserInfo from '../../../../auth/hooks/UserInfo';
import { OrganizationAccessPolicy } from '../../../../common/constants/RouteNames';
import type { SourceFilters } from '../../../../services/identity/DirectoryService';
import { UiDrawer } from '../../../common/UiDrawer';
import UiForm from '../../../common/UiForm';
import { UiFormActionButtons } from '../../../common/UiForm/UiFormActionButtons';
import useAddOrganizationAccessPolicyMembersViewModel from './AddOrganizationAccessPolicyMembersViewModel';

const useStyles = makeStyles(theme => createStyles({
    body: { marginBottom: '48px' },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    formButtons: { display: 'flex' },
    cancelButton: { marginRight: '10px' },
}));

const AddOrganizationAccessPolicyMembersDrawerComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const { token } = useUserInfo();

    const {
        loading,
        methods: {
            handleSubmit,
            control,
            formState,
        },
        onSubmit,
        peoplePickerChangedCallback,
        peoplePickerErrorCallback,
        peoplePickerLoadingCallback,
        errorMessage,
    } = useAddOrganizationAccessPolicyMembersViewModel();

    const {
        isValid, isDirty,
    } = formState;

    const {
        open, close,
    } = useModalState(OrganizationAccessPolicy);

    return (
        <UiDrawer
            title={translate({ id: 'CLIENT_ADD_ALLOWED_MEMBERS' })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(),
            }}>

            <UiForm
                onSubmit={handleSubmit(onSubmit)}
                actions={<UiFormActionButtons
                    showActions
                    classes={classes}
                    loading={loading}
                    isSubmitDisabled={!isValid || !isDirty}
                    onSubmit={handleSubmit(onSubmit)}
                    close={close}
                    altSaveText={translate({ id: 'CLIENT_ADD' })}
                />}>
                <div className={classes.body} />
                {errorMessage && <>
                    <PortalAlertBar
                        status="error"
                        cancelable={false}>
                        {errorMessage}
                    </PortalAlertBar>
                    <div style={{ marginBottom: '16px' }} />
                </>}
                <Controller
                    render={props => (
                        <PortalPeoplePicker
                            token={process.buildConfigs.passPeoplePickerToken ? (token || undefined) : undefined}
                            sourceFilters={[
                                'localUsers',
                                'localGroups',
                                'directoryUsers',
                                'directoryGroups',
                            ] as SourceFilters[]}
                            value={props.value}
                            maxHeight="200px"
                            onPeoplePickerChanged={peoplePickerChangedCallback}
                            onPeoplePickerError={peoplePickerErrorCallback}
                            onPeoplePickerLoading={peoplePickerLoadingCallback}
                            onPeoplePickerResolving={peoplePickerLoadingCallback}
                        />
                    )}
                    control={control}
                    name="entitiesToAdd"
                />
            </UiForm>

        </UiDrawer>
    );
};

export default AddOrganizationAccessPolicyMembersDrawerComponent;
