import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import { UiProgressButton } from '@experiences/ui-common';
import { useRouteResolver } from '@experiences/util';
import {
    Breadcrumbs,
    Button,
    CircularProgress,
    TextField,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { PortalPeoplePicker } from '@uipath/portal-shell-react';
import clsx from 'clsx';
import {
    isEqual,
    omit,
} from 'lodash';
import { useSnackbar } from 'notistack';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    useHistory,
    useRouteMatch,
} from 'react-router';
import { Link } from 'react-router-dom';
import useSWR from 'swr';

import useUserInfo from '../../../auth/hooks/UserInfo';
import { notificationType } from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useIsAdminRevampEnabled } from '../../../common/hooks/useIsAdminRevampEnabled';
import { useOrganizationName } from '../../../common/hooks/useOrganizationName';
import type { IDirectoryEntry } from '../../../common/interfaces/cis/directory';
import { DirectoryEntityType } from '../../../common/interfaces/cis/directory';
import type { IGroup } from '../../../common/interfaces/cis/group';
import type {
    ISAMLProvisioningCondition,
    ISAMLRule,
} from '../../../common/interfaces/cis/saml';
import type { SourceFilters } from '../../../services/identity/DirectoryService';
import {
    createRule,
    getRuleById,
    ruleUrl,
    updateRule,
} from '../../../services/identity/RuleService';
import {
    accountGlobalId,
    isAdminSelector,
} from '../../../store/selectors';
import UiForm from '../../common/UiForm';
import UiPageContainer from '../../common/UiPageContainer/UiPageContainer';
import { UiPanel } from '../../common/UiPanel/UiPanel';
import AdminBreadCrumbs from '../../organizationsettings/AdminBreadCrumbs';
import SAMLProvisioningConditionGenerator from './SAMLProvisioningConditionGenerator';

const useStyles = makeStyles(theme =>
    createStyles({
        loader: { margin: 'auto' },
        body: {
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
        },
        breadcrumbText: {
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForeground,
            fontWeight: 400,
        },
        breadcrumbClickable: {
            cursor: 'pointer',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        section: { marginBottom: '16px' },
        subTitle: {
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 600,
            marginBottom: '8px',
        },
        labelText: {
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 600,
            marginBottom: '8px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputControl: { marginBottom: '16px' },
        footer: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        footerButton: {
            color: theme.palette.semantic.colorPrimary,
            marginLeft: '8px',
            '& > a': {
                color: theme.palette.semantic.colorForegroundInverse,
                'text-decoration': 'none !important',
            },
        },
        header: {
            paddingTop: '24px',
            paddingLeft: '20px',
            paddingRight: '20px',
        },
    }),
);

interface ISAMLRuleForm extends Omit<ISAMLRule, 'partitionGlobalId' | 'enabled' | 'assignedGroups' | 'definition'> {
    groups: IDirectoryEntry[];
    conditions: ISAMLProvisioningCondition[];
}

const CreateEditSAMLProvisioningRuleComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const { enqueueSnackbar } = useSnackbar();

    const setErrorMessage = useCentralErrorSetter();
    const { getErrorMessage } = useGetErrorInfo();

    const createNotification = useCallback(
        (message: string, type = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    const history = useHistory();
    const match = useRouteMatch<{ type: 'add'; id: string }>();
    const getRoute = useRouteResolver();
    const { token } = useUserInfo();

    const {
        type, id,
    } = useMemo<{ type: 'add' | 'edit'; id: string }>(
        () => ({
            type: match.params.type === 'add' ? 'add' : 'edit',
            id: match.params.id,
        }),
        [ match ],
    );

    const isAdmin = useSelector(isAdminSelector);
    const partitionGlobalId = useSelector(accountGlobalId);
    const isAdminRevampEnabled = useIsAdminRevampEnabled();
    const organizationName = useOrganizationName();

    const {
        data: rule, isValidating, error, mutate,
    } = useSWR<ISAMLRule, Error>(
        type === 'edit' ? [ ruleUrl, partitionGlobalId, id ] : null,
        getRuleById,
    );

    const [ loading, setLoading ] = useState(false);

    const methods = useForm<ISAMLRuleForm>({
        mode: 'onChange',
        defaultValues: {
            name: '',
            description: '',
            groups: [],
            conditions: [],
        },
    });

    const {
        control, handleSubmit, formState, getValues, setValue, setError, clearErrors, reset, watch,
    } = methods;

    const {
        isDirty, isValid, errors,
    } = formState;

    useEffect(() => {
        if (type !== 'edit') {
            return;
        }

        if (rule) {
            reset({
                name: rule.name,
                description: rule.description,
                groups: ((Array.isArray(rule.assignedGroups)
                    ? rule.assignedGroups
                    : [ rule.assignedGroups ]) as IGroup[])
                    .map((group: IGroup) => ({
                        identifier: group.id,
                        displayName: group.name,
                        identityName: group.name,
                        source: group.type === 0 ? 'local' : 'directory',
                        type: DirectoryEntityType.group,
                    })),
                conditions: typeof rule.definition === 'string'
                    ? JSON.parse(rule.definition).Conditions as ISAMLProvisioningCondition[]
                    : rule.definition,
            });
        }
    }, [ reset, rule, type ]);

    const goBack = useCallback(() => {
        history.push(getRoute(RouteNames.SecuritySettingsSAMLProvisioningRules));
    }, [ history, getRoute ]);

    const onSubmit = useCallback(
        async (data: ISAMLRuleForm) => {
            setLoading(true);
            const mappedData = {
                ...data,
                ruleId: id ? parseInt(id) : undefined,
                partitionGlobalId,
                enabled: rule?.enabled ?? true,
                definition: JSON.stringify({
                    GroupsToAssign: data.groups.map(group => group.identifier),
                    Conditions: data.conditions,
                }),
                groups: undefined,
                conditions: undefined,
            };

            try {
                if (type === 'add') {
                    await createRule(mappedData);
                } else {
                    await updateRule(mappedData);
                }

                createNotification(
                    translate({
                        id: type === 'add'
                            ? 'CLIENT_SAML_PROVISIONING_RULES_CREATED_SUCCESSFULLY'
                            : 'CLIENT_SAML_PROVISIONING_RULES_UPDATED_SUCCESSFULLY',
                    }),
                );

                goBack();
            } catch (error) {
                const response = getErrorMessage(error);
                setErrorMessage(response);
            } finally {
                setLoading(false);
            }
        },
        [ createNotification, getErrorMessage, goBack, id, partitionGlobalId, rule?.enabled, setErrorMessage, translate, type ],
    );

    const peoplePickerChangedCallback = useCallback((event: any) => {
        const prevWithoutChip =
                    getValues('groups')?.map(chip => {
                        if (typeof chip === 'string') {
                            return chip;
                        }
                        return omit(chip, [ 'optionId', 'chipType' ]);
                    }) ?? [];
        const curWithoutChip = event.detail.data.map((chip: IDirectoryEntry) => omit(chip, [ 'optionId', 'chipType' ]));
        const valuesChanged = !isEqual(prevWithoutChip, curWithoutChip);
        setValue('groups', event.detail.data, {
            shouldDirty: valuesChanged,
            shouldValidate: valuesChanged,
        });
    }, [ getValues, setValue ]);

    const peoplePickerErrorCallback = useCallback((event: any) => {
        if (event.detail) {
            setError('groups', { type: 'invalid' });
        } else {
            clearErrors('groups');
        }
    }, [ clearErrors, setError ]);

    const peoplePickerLoadingCallback = useCallback((event: any) => setLoading(event.detail), []);

    const mainContent = useMemo(() => (
        isValidating ? (
            <CircularProgress className={classes.loader} />
        ) : error ? (
            <div className={classes.body}>
                <Typography>
                    {translate({ id: 'CLIENT_UNKNOWN_ERROR_FROMBACKEND' })}
                </Typography>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                        mutate();
                    }}
                    style={{ marginTop: '12px' }}
                >
                    {translate({ id: 'CLIENT_RETRY' })}
                </Button>
            </div>
        ) : (
            <FormProvider {...methods}>
                <UiForm
                    onSubmit={handleSubmit(onSubmit)}
                    actions={<div className={classes.footer}>
                        <Button
                            className={classes.footerButton}
                            onClick={goBack}
                            data-cy="saml-cancel-button">
                            {translate({ id: 'CLIENT_CANCEL' })}
                        </Button>
                        <UiProgressButton
                            type="submit"
                            className={classes.footerButton}
                            loading={loading}
                            disabled={!isDirty || !isValid || Object.entries(errors).length > 0 || !watch('groups')?.length}
                            variant="contained"
                            data-cy="saml-test-and-save-button"
                        >
                            {translate({ id: 'CLIENT_SAVE' })}
                        </UiProgressButton>
                    </div>}>
                    <div className={classes.section}>
                        <Typography
                            className={classes.subTitle}>
                            {translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_BASIC_DETAILS' })}
                        </Typography>
                        <Controller
                            as={TextField}
                            control={control}
                            rules={{
                                required: translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }),
                                validate: p => !!p.trim(),
                            }}
                            required
                            name="name"
                            label={translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_RULE_NAME' })}
                            placeholder={translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_RULE_NAME_PLACEHOLDER' })}
                            variant="outlined"
                            fullWidth
                            error={!!errors.name}
                            helperText={errors.name?.message}
                            className={classes.inputControl}
                            data-cy="jit-rule-name"
                        />
                        <Controller
                            as={TextField}
                            control={control}
                            name="description"
                            label={translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_RULE_DESCRIPTION' })}
                            placeholder={translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_RULE_DESCRIPTION_PLACEHOLDER' })}
                            variant="outlined"
                            fullWidth
                            multiline
                            minRows={3}
                            maxRows={3}
                            error={!!errors.description}
                            helperText={errors.description?.message}
                            className={classes.inputControl}
                            data-cy="jit-rule-description"
                        />
                    </div>
                    <div className={classes.section}>
                        <Typography
                            className={classes.subTitle}>
                            {translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_CONDITIONS' })}
                        </Typography>
                        <SAMLProvisioningConditionGenerator />
                    </div>
                    <div className={classes.section}>
                        <Typography
                            className={classes.subTitle}>
                            {translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_ASSIGN_TO_GROUPS' })}
                        </Typography>
                        <Controller
                            render={props => (
                                <PortalPeoplePicker
                                    token={token}
                                    sourceFilters={[ 'localGroups' ] as SourceFilters[]}
                                    value={props.value}
                                    maxHeight="200px"
                                    onPeoplePickerChanged={peoplePickerChangedCallback}
                                    onPeoplePickerError={peoplePickerErrorCallback}
                                    onPeoplePickerLoading={peoplePickerLoadingCallback}
                                    onPeoplePickerResolving={peoplePickerLoadingCallback}
                                />
                            )}
                            control={control}
                            rules={{ required: translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }) }}
                            name="groups"
                        />
                    </div>
                </UiForm>
            </FormProvider>
        )
    ), [
        classes,
        control,
        error,
        errors,
        goBack,
        handleSubmit,
        isDirty,
        isValid,
        isValidating,
        loading,
        methods,
        mutate,
        onSubmit,
        peoplePickerChangedCallback,
        peoplePickerErrorCallback,
        token,
        translate,
        watch,
    ]);

    const breadCrumbLinks = useMemo(() =>
        [
            {
                link: RouteNames.OrganizationAdminHome,
                name: organizationName,
            },
            {
                link: RouteNames.SecuritySettings,
                name: translate({ id: 'CLIENT_SECURITY_SETTINGS' }),
            },
            {
                link: RouteNames.SecuritySettingsSAMLProvisioningRules,
                name: translate({ id: 'CLIENT_PAGE_SAML_PROVISIONING_RULES' }),
            },
            {
                link: RouteNames.SecuritySettingsSAMLProvisioningRulesAddEdit,
                name: translate({
                    id: type === 'add'
                        ? 'CLIENT_SAML_PROVISIONING_RULES_ADD_NEW_RULE'
                        : 'CLIENT_SAML_PROVISIONING_RULES_EDIT_RULE',
                }),
            },
        ],
    [ organizationName, translate, type ]);

    return (
        isAdminRevampEnabled ?
            <UiPageContainer
                position="left"
                header={AdminBreadCrumbs(breadCrumbLinks)}
            >
                {mainContent}
            </UiPageContainer>
            :
            <UiPanel
                header={{
                    title: (
                        <Breadcrumbs separator=">">
                            <Link
                                className={clsx(classes.breadcrumbText, classes.breadcrumbClickable)}
                                to={getRoute(RouteNames.SecuritySettings)}
                            >
                                {translate({ id: 'CLIENT_AUTHENTICATION' })}
                            </Link>
                            <Link
                                className={clsx(classes.breadcrumbText, classes.breadcrumbClickable)}
                                to={getRoute(RouteNames.SecuritySettingsSAMLProvisioningRules)}
                            >
                                {translate({ id: 'CLIENT_PAGE_SAML_PROVISIONING_RULES' })}
                            </Link>
                            <span
                                className={classes.breadcrumbText}
                                role="heading"
                                aria-level={1}
                                data-cy="jit-rule-form-type">
                                {translate({
                                    id: type === 'add'
                                        ? 'CLIENT_SAML_PROVISIONING_RULES_ADD_NEW_RULE'
                                        : 'CLIENT_SAML_PROVISIONING_RULES_EDIT_RULE',
                                })}
                            </span>
                        </Breadcrumbs>
                    ),
                }}
            >
                {mainContent}
            </UiPanel>
    );
};

export default CreateEditSAMLProvisioningRuleComponent;
