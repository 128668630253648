import { portalTelemetry } from '@experiences/telemetry';
import { GlobalStyles } from '@experiences/theme';
import { useRouteResolver } from '@experiences/util';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as RouteNames from '../../../../../src/common/constants/RouteNames';
import { ReactComponent as UpgradeOrangeIcon } from '../../../../../src/images/upgradeOrange.svg';
import { LicensingRobotServicesLink } from '../../../../common/constants/documentation/DocumentationLinks.default';
import { useDocumentationLinks } from '../../../../common/hooks/useDocumentationLink';
import { userGlobalId } from '../../../../store/selectors';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        root: {
            width: '100%',
            padding: '16px',
            borderRadius: '3px',
            marginTop: '12px',
            border: `1px ${theme.palette.semantic.colorBorderDeEmp} solid`,
        },
        content: {
            flexDirection: 'column',
            width: '100%',
        },
        header: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            color: theme.palette.semantic.colorForeground,
            fontWeight: 600,
        },
        upgradeOrangeIcon: {
            width: '22px',
            height: '22px',
            marginRight: '8px',
        },
        subHeader: {
            marginTop: '8px',
            fontSize: '14px',
            color: theme.palette.semantic.colorForeground,
        },
        checkIcon: {
            color: theme.palette.semantic.colorSuccessIcon,
            marginRight: '10px',
        },
        buttonContainer: {
            display: 'flex',
            marginTop: '10px',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        startTrial: {
            fontWeight: 600,
            color: theme.palette.semantic.colorForegroundLink,
            '&:hover': {
                textDecoration: 'none',
                color: theme.palette.semantic.colorForegroundLinkPressed,
            },
        },
        learnMore: {
            fontWeight: 600,
            marginRight: '24px',
            color: theme.palette.semantic.colorForegroundLink,
            '&:hover': {
                textDecoration: 'none',
                color: theme.palette.semantic.colorForegroundLinkPressed,
            },
        },
    }),
}));

const NeedMoreLicensesComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const history = useHistory();
    const getRoute = useRouteResolver();
    const getLocalizedLink = useDocumentationLinks({ excludedLanguages: [ 'pt', 'pt-BR', 'zh-CN', 'zh-TW' ] });

    const userId = useSelector(userGlobalId);

    return (
        <div
            className={classes.root}
            data-cy="try-enterprise-trial-license-nudge">
            <div className={classes.content}>
                <Typography
                    className={classes.header}
                    data-cy="header-text">
                    <UpgradeOrangeIcon className={classes.upgradeOrangeIcon} />
                    {translate({ id: 'CLIENT_NEED_MORE_LICENSES' })}
                </Typography>
                <Typography
                    className={classes.subHeader}
                    data-cy="sub-header-text">
                    {translate({ id: 'CLIENT_SIGN_UP_FOR_FREE_TRIAL_LICENSES_NUDGE' })}
                </Typography>
            </div>
            <div
                className={classes.content}
                data-cy="enterprise-trial-services">
                <div className={classes.buttonContainer}>
                    <a
                        className={clsx(classes.learnMore, classes.a)}
                        target="_blank"
                        rel="noreferrer"
                        href={getLocalizedLink({ articleSlug: LicensingRobotServicesLink })}
                        data-cy="learn-more-link"
                    >
                        {translate({ id: 'CLIENT_LEARN_MORE' })}
                    </a>
                    <a
                        className={clsx(classes.startTrial, classes.a)}
                        data-cy="start-trial-button"
                        onClick={() => {
                            portalTelemetry.trackTrace({
                                message: `Need more Licenses form: ${userId} requested enterprise trial`,
                                severityLevel: SeverityLevel.Information,
                            });
                            history.push(getRoute(RouteNames.TenantLicensingUpgradeToEnterprise));
                        }}
                    >
                        {translate({ id: 'CLIENT_START_TRIAL' })}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default NeedMoreLicensesComponent;
