import { CanaryTenantLinks } from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { GlobalStyles } from '@experiences/theme';
import { UiSelect } from '@experiences/ui-common';
import { useLocalizedLinks } from '@experiences/util';
import {
    Checkbox,
    CircularProgress,
    FormControlLabel,
    TextField,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { TenantRegionDocsLinks } from '../../../common/constants/documentation/DocumentationLinks.default';
import { useDocumentationLinks } from '../../../common/hooks/useDocumentationLink';
import type { ITenant } from '../../../common/interfaces/tenant/tenant';
import UiColorPicker from '../../common/UiColorPicker';
import useTenantGeneralFormViewModel from './TenantGeneralFormViewModel';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        colorPicker: { marginLeft: '8px' },
        tenant: {
            display: 'flex',
            margin: '16px 0 16px 0',
            flexDirection: 'row',
        },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        error: {
            margin: '20px 24px',
            flex: '1 1 100%',
        },
        tenantSettingsForm: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
        },
        smallText: { fontSize: '12px' },
        mediumText: { fontSize: '14px' },
        canaryRegionHelperText: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: '8px',
        },
        redAsterisk: {
            color: theme.palette.semantic.colorErrorText,
            fontSize: '12px',
        },
    }),
}));

const regex = /^(?!.*_$)[a-zA-Z][A-Za-z0-9_]+$/;

const TenantGeneralForm: React.FC<{
    type?: 'add' | 'edit';
    tenant?: ITenant;
    disableRegion?: boolean;
    disableForm?: boolean;
    width?: string; }> = ({
    type, tenant, disableRegion, disableForm, width,
}) => {
    const DisableFeatureFedRamp = useFeatureFlagValue(Features.DisableFeatureFedRamp.name);

    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const getLocalizedLink = useDocumentationLinks({ excludedLanguages: [ 'es-MX', 'ko', 'pt', 'pt-BR', 'tr', 'zh-CN', 'zh-TW' ] });
    const getLocalizedLinkConstant = useLocalizedLinks();

    const {
        tenantsWithoutServices,
        validationErrorHandlers,
        isCanaryTenant,
        isCanaryChecked,
        canaryTenantExists,
        isRegionEnabled,
        DefaultRegions,
        translatedRegion,
        methods: {
            register,
            control,
            errors,
        },
        canaryTenantFormEnabled,
        isAdminRevampEnabled,
    } = useTenantGeneralFormViewModel(tenant, type);

    const [ validationErrors ] = validationErrorHandlers;

    const validateTenantName = useCallback((value: string) => {
        if (!(type === 'edit' && value && tenant?.name.toLowerCase() === value.toLowerCase())
        && (tenantsWithoutServices?.map(tenantIterator => tenantIterator.name.toLowerCase()).includes(value.toLowerCase()) ?? false)) {
            return translate({ id: 'CLIENT_TENANT_DUPLICATE_NAME' });
        }
        if (!value.match(regex)) {
            return translate({ id: 'CLIENT_WARN_NAME' }, {
                0: 32,
                1: 2,
            });
        }
        return true;
    }, [ tenant?.name, tenantsWithoutServices, translate, type ]);

    if (isRegionEnabled && !DefaultRegions?.length && !DisableFeatureFedRamp) {
        return <CircularProgress />;
    }

    return (
        <div
            className={classes.tenantSettingsForm}
            data-cy="tenant-settings-component"
            style={{
                width,
                padding: type === 'add' && isAdminRevampEnabled ? '0 16px' : '',
            }}>
            <div className={classes.tenant}>
                <TextField
                    name="name"
                    disabled={disableForm}
                    inputRef={register({
                        required: translate(
                            { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                            { 0: translate({ id: 'CLIENT_TENANT_NAME_CREATE_EDIT' }) },
                        ),
                        maxLength: {
                            value: 32,
                            message: translate({ id: 'CLIENT_WARN_NAME' }, {
                                0: 32,
                                1: 2,
                            }),
                        },
                        minLength: {
                            value: 2,
                            message: translate({ id: 'CLIENT_WARN_NAME' }, {
                                0: 32,
                                1: 2,
                            }),
                        },
                        validate: (value: string) => validateTenantName(value),
                    })}
                    label={translate({ id: 'CLIENT_TENANT_NAME_CREATE_EDIT' })}
                    InputLabelProps={{ id: 'tenantNameLabel' }}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                    fullWidth
                    variant="outlined"
                    InputProps={{ className: 'Tall' }}
                    inputProps={{ 'aria-labelledby': 'tenantNameLabel' }}
                    data-cy="create-edit-tenant-name"
                    required
                />
                <div className={classes.colorPicker}>
                    <Controller
                        name="color"
                        control={control}
                        render={({
                            value, onChange,
                        }) => <UiColorPicker
                            value={value}
                            onChange={onChange}
                            disabled={disableForm}
                        />}
                    />
                </div>
            </div>
            {canaryTenantFormEnabled && <div style={{ marginBottom: '24px' }}>
                <FormControlLabel
                    control={<Checkbox
                        disabled={isCanaryTenant || canaryTenantExists}
                        defaultChecked={isCanaryChecked || isCanaryTenant}
                        data-cy="canary-tenant-checkbox"
                    />}
                    label={
                        <Typography className={classes.mediumText}>
                            {translate({ id: 'CLIENT_USE_AS_CANARY' })}
                        </Typography>
                    }
                    style={{ marginTop: '8px' }}
                    id="termsAndConditionsAccepted"
                    name="customProperties.isCanaryTenant"
                    inputRef={register()}
                />
                <Typography className={classes.smallText}>
                    {translate({
                        id: canaryTenantExists && type === 'add'
                            ? 'CLIENT_CANARY_TENANT_ONLY_ONE'
                            : 'CLIENT_CANARY_DESCRIPTION',
                    })}
                </Typography>
                <a
                    className={clsx(classes.a, classes.smallText)}
                    target="_blank"
                    rel="noreferrer"
                    href={getLocalizedLinkConstant(CanaryTenantLinks)}
                >
                    {translate({ id: 'CLIENT_LEARN_MORE' })}
                </a>
            </div> }
            {isRegionEnabled && !!DefaultRegions?.length && !DisableFeatureFedRamp &&
                <>
                    <UiSelect
                        dataCy="create-edit-tenant-region"
                        name="region"
                        inputLabel={translate({ id: 'CLIENT_TENANT_REGION' })}
                        error={!!errors.region}
                        options={translatedRegion}
                        disabled={disableRegion ?? disableForm ?? isCanaryChecked}
                        required
                        control={control}
                        helperText={
                            (errors.region?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })) ||
                            ((validationErrors.unsupported.length > 0 || validationErrors.altRegion.length > 0) &&
                                translate(
                                    { id: 'CLIENT_TENANT_REGION_HELPER_TEXT' },
                                    {
                                        LEARNMORE: (
                                            <a
                                                className={classes.a}
                                                href={getLocalizedLink({ articleSlug: TenantRegionDocsLinks })}
                                                rel="noreferrer"
                                                target="_blank"
                                            >
                                                {translate({ id: 'CLIENT_LEARN_MORE' })}
                                            </a>
                                        ),
                                    })
                            )
                        }
                    />
                    {isCanaryChecked &&
                        <div className={classes.canaryRegionHelperText}>
                            <Typography className={classes.redAsterisk}>
                                *
                            </Typography>
                            <Typography className={classes.smallText}>
                                {translate({ id: 'CLIENT_CANARY_REGION_RESTRICTION' })}
                            </Typography>
                        </div>}
                </>}
        </div>
    );
};

export default TenantGeneralForm;
