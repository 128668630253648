import { NavCustomizationSetting } from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import type { Persona } from '@experiences/interfaces';
import { useLocalization } from '@experiences/locales';
import { logEvent } from '@experiences/telemetry';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens from '@uipath/apollo-core';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import {
    DeliveryType,
    ProductType,
    useDocumentationLinks,
} from '../../common/hooks/useDocumentationLink';
import useIsCustomizationElementVisible from '../../common/hooks/useIsCustomizationElementVisible';
import type {
    sidebarLinkContainers,
    sidebarLinks,
} from '../../common/interfaces/sidebar';
import {
    getSidebarResource,
    sidebarURL,
} from '../../services/SidebarCMS';
import { SidebarBackup } from '../../services/utility/SidebarBackup';
import { SidebarK8s } from '../../services/utility/SidebarK8s';
import { persona } from '../../store/selectors';
import { HomePageDownloadStudioButton } from './HomePageSideBarComponent';

const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            width: '100%',
            height: '21px',
            fontFamily: Tokens.FontFamily.FontTitle,
            fontWeight: '600',
            fontSize: '18px',
            lineHeight: '24px',
            marginBottom: '16px',
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1,
            wordWrap: 'break-word',
            color: theme.palette.semantic.colorForeground,
        },
        titleText: {
            width: '100%',
            lineHeight: '20px',
            fontWeight: '400',
            fontSize: '14px',
            marginBottom: '7px',
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            color: theme.palette.semantic.colorForeground,
            wordWrap: 'break-word',
        },
        linkText: {
            width: '100%',
            height: '20px',
            lineHeight: '20px',
            fontSize: '14px',
            fontWeight: '600',
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1,
            wordWrap: 'break-word',
        },
        linkContainer: {
            paddingTop: '32px',
            paddingBottom: '32px',
        },
        linkContainerSubtitle: {
            width: '100%',
            height: '23px',
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1,
            wordWrap: 'break-word',
        },
    }),
);

const HomePageSidebarLinksComponent: React.FC = () => {
    const language = useLocalization();
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const dominantPersona = useSelector(persona);
    const isDownloadsVisible = useIsCustomizationElementVisible(NavCustomizationSetting.ResourceCenterHidden);
    const EnableContentfulSideBar = useFeatureFlagValue(Features.EnableContentfulSideBar.name);

    const {
        data, error,
    } = useSWR(
        process.buildConfigs.name !== 'k8s' && EnableContentfulSideBar ? [ language, dominantPersona, sidebarURL ] : null,
        getSidebarResource,
    );

    const translateSidebarText = (str: string) =>
        (!error && process.buildConfigs.name !== 'k8s') ? str : translate({ id: 'CLIENT_SIDEBAR_' + str });

    const sidebarData = useMemo(() => {
        if (process.buildConfigs.name === 'k8s') {
            return SidebarK8s.sidebarContent.sidebarLinkContainersCollection.items;
        } else if (error) {
            return SidebarBackup.sidebarContent.sidebarLinkContainersCollection.items;
        }
        return data?.sidebarContent?.sidebarLinkContainersCollection.items.filter(
            (obj: sidebarLinkContainers) => obj != null
        );
    }, [ data?.sidebarContent?.sidebarLinkContainersCollection.items, error ]);

    const downloadStudioPosition = useMemo(() => {
        if (process.buildConfigs.name === 'k8s') {
            return 0;
        } else if (error) {
            return SidebarBackup.sidebarContent.downloadStudioPosition;
        }
        return data?.sidebarContent?.downloadStudioPosition ?? 0;
    }, [ data?.sidebarContent?.downloadStudioPosition, error ]);

    return (
        <>
            {sidebarData && sidebarData.length > 0 ? (
                sidebarData.map(
                    (linkContainerItem: sidebarLinkContainers, linkContainerId: number) => (
                        <div key={linkContainerItem.title}>
                            {isDownloadsVisible && linkContainerId === downloadStudioPosition && (
                                <StudioDownloadLinksContainer linkContainerId={linkContainerId} />
                            )}
                            <div>
                                <div
                                    className={classes.linkContainer}
                                    data-cy={`link-container-${linkContainerId}`}
                                    style={{
                                        paddingTop:
                                                linkContainerId === 0 && downloadStudioPosition !== 0
                                                    ? '0px'
                                                    : '32px',
                                    }}
                                >
                                    {linkContainerItem.subtitle && (
                                        <div
                                            className={
                                                classes.linkContainerSubtitle
                                            }
                                            data-cy={`link-subtitle-${linkContainerId}`}
                                        >
                                            {translateSidebarText(
                                                linkContainerItem.subtitle,
                                            )}
                                        </div>
                                    )}
                                    <Typography
                                        data-cy={`link-title-${linkContainerId}`}
                                        component="h3"
                                        variant="h3"
                                        className={classes.title}
                                    >
                                        {translateSidebarText(
                                            linkContainerItem.title,
                                        )}
                                    </Typography>
                                    {linkContainerItem.sidebarLinksCollection?.items.map(
                                        (
                                            linkItem: sidebarLinks,
                                            linkId: number,
                                        ) => (
                                            <div
                                                key={linkContainerItem.title + linkItem.titleText}
                                                style={{
                                                    marginTop:
                                                            linkId === 0
                                                                ? '0'
                                                                : '24px',
                                                }}
                                            >
                                                <div
                                                    data-cy={`link-titleText-${linkContainerId}-${linkId}`}
                                                    className={
                                                        classes.titleText
                                                    }
                                                >
                                                    {translateSidebarText(
                                                        linkItem.titleText,
                                                    )}
                                                </div>
                                                <div
                                                    data-cy={`link-linkText-${linkContainerId}-${linkId}`}
                                                    className={
                                                        classes.linkText
                                                    }
                                                >
                                                    <SideBarLink
                                                        linkItem={linkItem}
                                                        dominantPersona={dominantPersona}
                                                        error={error} />
                                                </div>
                                            </div>
                                        ),
                                    )}
                                </div>
                                { linkContainerId !== (sidebarData.length - 1) &&
                                        <Divider data-testid={`sidebar-content-divider-${linkContainerId}`} /> }
                            </div>
                            {
                                isDownloadsVisible
                                    && linkContainerId === sidebarData.length - 1
                                    && downloadStudioPosition >= sidebarData.length
                                    && <StudioDownloadLinksContainer linkContainerId={linkContainerId} />
                            }
                        </div>
                    ),
                )
            ) : (
                <div>
                    {isDownloadsVisible && sidebarData && sidebarData.length === 0 && <StudioDownloadLinksContainer linkContainerId={0} />}
                </div>
            )}
        </>
    );
};

interface SideBarLinkProps {
    linkItem: sidebarLinks;
    dominantPersona: Persona;
    error: any;
}

const SideBarLink = ({
    linkItem, dominantPersona, error,
}: SideBarLinkProps) => {
    const { formatMessage: translate } = useIntl();

    const translateSidebarText = (str: string) =>
        (!error && process.buildConfigs.name !== 'k8s') ? str : translate({ id: 'CLIENT_SIDEBAR_' + str });

    const handleClick = React.useCallback(() => {
        logEvent('Side bar link clicked', {
            name: 'SideBar',
            group: 'HomePage',
            code: '[IN-HP-60]',
        }, {
            Persona: dominantPersona,
            Link: linkItem,
        });
        window.open(linkItem.linkHref, '_newtab');
    }, [ dominantPersona, linkItem ]);

    return <Link
        data-cy={`link-anchor-${linkItem.titleText}`}
        onClick={handleClick}
        underline="none"
        tabIndex={0}
    >
        {translateSidebarText(
            linkItem.linkText,
        )}
    </Link>;
};

type studioDLCardsProps = {
    linkContainerId: number;
};

const StudioDownloadLinksContainer = ({ linkContainerId }: studioDLCardsProps) => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const getLocalizedLinks = useDocumentationLinks();
    const dominantPersona = useSelector(persona);

    const handleClick = React.useCallback(() => {
        const link = getLocalizedLinks({
            product: ProductType.STUDIO,
            delivery: DeliveryType.STANDALONE,
        });
        logEvent('Studio link clicked', {
            name: 'SideBar',
            group: 'HomePage',
            code: '[IN-HP-61]',
        }, {
            Persona: dominantPersona,
            Link: link,
        });
        window.open(link, '_newtab');
    }, [ dominantPersona, getLocalizedLinks ]);

    return (
        <>
            <div
                className={classes.linkContainer}
                style={{ paddingTop: linkContainerId === 0 ? '0px' : '32px' }}
            >
                <Typography
                    data-cy="studio-header-linkscontainer"
                    component="h2"
                    variant="h2"
                    className={classes.title}
                    style={{
                        WebkitLineClamp: 2,
                        height: '48px',
                        marginBottom: '24px',
                    }}
                >
                    {translate({ id: 'CLIENT_STUDIO_HEADER' })}
                </Typography>
                <div
                    data-cy="studio-subtext-linkscontainer"
                    className={classes.titleText}
                    style={{
                        WebkitLineClamp: 4,
                        height: '64px',
                    }}
                >
                    {translate({ id: 'CLIENT_STUDIO_SUBTEXT' }) + '  '}
                    <Link
                        underline="none"
                        onClick={handleClick}
                        data-cy="learn-more-studio-link-linkscontainer"
                    >
                        {translate({ id: 'CLIENT_LEARN_MORE' })}
                    </Link>
                </div>
                <div
                    style={{
                        height: '40px',
                        marginTop: '20px',
                    }}
                >
                    <HomePageDownloadStudioButton />
                </div>
            </div>
            <Divider />
        </>
    );
};

export default HomePageSidebarLinksComponent;
