import {
    AccountLicense,
    NavCustomizationSetting,
} from '@experiences/constants';
import { useLocalization } from '@experiences/locales';
import { useRouteResolver } from '@experiences/util';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Link,
    Typography,
    useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens from '@uipath/apollo-core';
import clsx from 'clsx';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import downloadResources from '../../common/constants/DownloadResources';
import * as RouteNames from '../../common/constants/RouteNames';
import useCheckLicense from '../../common/hooks/useCheckLicense';
import {
    DeliveryType,
    ProductType,
    useDocumentationLinks,
} from '../../common/hooks/useDocumentationLink';
import useIsCustomizationElementVisible from '../../common/hooks/useIsCustomizationElementVisible';
import { ReactComponent as RobotDesign } from '../../images/robotDesign.svg';
import { ReactComponent as StudioDesign } from '../../images/studioDesign.svg';
import { studioDownloadMarketoUpdate } from '../../services/licensing/MarketoService';
import {
    accountGlobalId,
    accountLogicalName,
    accountType,
    profile,
} from '../../store/selectors';
import {
    logStudioDownload,
    StudioDownload,
} from '../../telemetry';
import VideoGuide from '../resourceCenter/VideoGuide';
import HomePageHeader from './helper/HomePageHeader';

const useStyles = makeStyles(theme =>
    createStyles({
        root: { display: 'flex' },
        buttonDefault: {
            height: '40px',
            backgroundColor: theme.palette.semantic.colorBackgroundSecondary,
            '&:hover': { color: theme.palette.semantic.colorForegroundLinkPressed },
            '&:focus': { color: theme.palette.semantic.colorForegroundLinkPressed },
        },
        fontBold: { fontWeight: 600 },
        cardDefaults: {
            borderRadius: 0,
            boxShadow: 'none',
            backgroundImage: 'none',
        },
        cardHeaderDefault: {
            padding: '10px 10px 0px 0px',
            fontFamily: Tokens.FontFamily.FontTitle,
            width: '95%',
            overflow: 'auto',
        },
        sidePanelColor: { backgroundColor: theme.palette.semantic.colorBackgroundSecondary },
        sidePanelCard: {
            flexDirection: 'column',
            height: 'auto',
        },
        sidePanelCardContent: {
            padding: '10px 10px 0px 0px',
            paddingBottom: '8px !important',
            overflow: 'auto',
        },
        sideBarText: { color: theme.palette.semantic.colorForegroundDeEmp },
        centered: {
            alignContent: 'center',
            display: 'flex',
            margin: '0 auto',
        },
        sideBarImageHeight: {
            height: '36px',
            width: '36px',
            '& .iconBlue': { fill: theme.palette.semantic.colorPrimary },
            '& .iconOrange': { fill: theme.palette.semantic.colorForegroundHigh },
        },
        sideBarImageMargin: { marginTop: '5px' },
        cardSpace: { marginBottom: '40px' },
        cardActions: { paddingLeft: '0px' },
        resourceCenter: { marginTop: '48px' },
        linkColor: { color: theme.palette.semantic.colorForegroundLink },
    }),
);

export const HomePageSideBarComponent = () => {
    const classes = useStyles();
    const theme = useTheme();
    const getRoute = useRouteResolver();
    const getLocalizedLinks = useDocumentationLinks();

    const { formatMessage: translate } = useIntl();
    const { notIncludesLicense } = useCheckLicense();

    const [ isVideoOpen, setIsVideoOpen ] = useState(false);

    const isDownloadsVisible = useIsCustomizationElementVisible(NavCustomizationSetting.ResourceCenterHidden);

    return (
        <div>
            {isVideoOpen && <VideoGuide closeHandler={() => setIsVideoOpen(false)} />}
            {isDownloadsVisible && <div className={clsx(classes.centered, classes.cardSpace)}>
                <Card className={clsx(classes.cardDefaults, classes.sidePanelColor, classes.sidePanelCard)}>
                    <div className={classes.root}>
                        <div>
                            <CardHeader
                                className={clsx(classes.cardHeaderDefault)}
                                disableTypography
                                title={
                                    <HomePageHeader
                                        label={translate({ id: 'CLIENT_STUDIO_HEADER' })}
                                        lineHeight="24px"
                                        color={theme.palette.semantic.colorForeground}
                                        fontSize="16px"
                                    />
                                }
                            />
                            <CardContent className={clsx(classes.sidePanelCardContent)}>
                                <Typography className={classes.sideBarText}>
                                    {translate({ id: 'CLIENT_STUDIO_SUBTEXT' })}
                                </Typography>
                                <Typography>
                                    <Link
                                        href={getLocalizedLinks({
                                            product: ProductType.STUDIO,
                                            delivery: DeliveryType.STANDALONE,
                                        })}
                                        target="_blank"
                                        className={classes.fontBold}
                                        data-cy="learn-more-studio-link"
                                    >
                                        {translate({ id: 'CLIENT_LEARN_MORE' })}
                                    </Link>
                                </Typography>
                            </CardContent>
                        </div>
                        <div>
                            <StudioDesign className={clsx(classes.sideBarImageHeight, classes.sideBarImageMargin)} />
                        </div>
                    </div>
                    <CardActions className={classes.cardActions}>
                        <HomePageDownloadStudioButton />
                    </CardActions>
                </Card>
            </div>}
            <div className={clsx(classes.centered)}>
                <Card className={clsx(classes.cardDefaults, classes.sidePanelColor, classes.sidePanelCard)}>
                    <div className={classes.root}>
                        <div>
                            <CardHeader
                                className={clsx(classes.cardHeaderDefault)}
                                disableTypography
                                title={
                                    <HomePageHeader
                                        label={translate({ id: 'CLIENT_ROBOT_HEADER' })}
                                        lineHeight="24px"
                                        color={theme.palette.semantic.colorForeground}
                                        fontSize="16px"
                                    />
                                }
                            />
                            <CardContent className={clsx(classes.sidePanelCardContent)}>
                                <Typography className={classes.sideBarText}>
                                    {translate({ id: 'CLIENT_ROBOT_SUBTEXT' })}
                                </Typography>
                                <Typography>
                                    <Link
                                        href={getLocalizedLinks({
                                            product: ProductType.ROBOT,
                                            delivery: DeliveryType.STANDALONE,
                                        })}
                                        target="_blank"
                                        className={classes.fontBold}
                                        data-cy="learn-more-robot-link"
                                    >
                                        {translate({ id: 'CLIENT_LEARN_MORE' })}
                                    </Link>
                                </Typography>
                            </CardContent>
                        </div>
                        <div>
                            <RobotDesign
                                className={classes.sideBarImageHeight}
                                style={{ width: '48px' }} />
                        </div>
                    </div>
                    {process.buildConfigs.enableVideoLink && (
                        <CardActions className={classes.cardActions}>
                            <Button
                                className={classes.buttonDefault}
                                style={{ width: '90%' }}
                                variant="outlined"
                                onClick={() => setIsVideoOpen(true)}
                                data-cy="watch-video-link"
                                startIcon={<PlayCircleOutlineOutlinedIcon />}
                            >
                                <HomePageHeader
                                    label={translate({ id: 'CLIENT_WATCH_VIDEO' })}
                                    variant="button" />
                            </Button>
                        </CardActions>
                    )}
                </Card>
            </div>
            {isDownloadsVisible && notIncludesLicense([ AccountLicense.FREEKIT ])
                && <div className={classes.resourceCenter}>
                    <Typography
                        variant="body1"
                        color="textSecondary">
                        {translate({ id: 'CLIENT_RESOURCE_CENTER_1' })}
                        {' '}
                        <RouterLink
                            to={getRoute(RouteNames.ResourceCenter)}
                            data-cy="resource-center-link"
                            className={clsx(classes.fontBold, classes.linkColor)}>
                            {translate({ id: 'CLIENT_RESOURCE_CENTER' }).toLocaleLowerCase()}
                        </RouterLink>
                        {' '}
                        {translate({ id: 'CLIENT_RESOURCE_CENTER_2' })}
                    </Typography>
                </div>}
        </div>
    );
};

export const HomePageDownloadStudioButton = () => {
    const classes = useStyles();
    const accountTypeSelector = useSelector(accountType);

    const { formatMessage: translate } = useIntl();

    const profileState = useSelector(profile);
    const accountGUID = useSelector(accountGlobalId);
    const logicalName = useSelector(accountLogicalName);
    const currentAccountLanguage = useLocalization();

    const updateMarketo = useCallback(
        async (editionType: string) => {
            await studioDownloadMarketoUpdate(editionType, profileState, accountGUID, logicalName, currentAccountLanguage);
        },
        [ profileState, accountGUID, logicalName, currentAccountLanguage ],
    );

    const studioEditionAndLink: {
        edition: 'Enterprise Cloud' | 'Enterprise Trial' | 'Community Edition' | 'Enterprise';
        link: string;
    } = useMemo(() => {
        if (!process.buildConfigs.userLicenseManagementCloud) {
            return {
                edition: 'Enterprise',
                link: downloadResources.ENTERPRISE_STUDIO_DOWNLOAD_LINK,
            };
        }

        switch (accountTypeSelector) {
            case 'ENTERPRISE':
                return {
                    edition: 'Enterprise Cloud',
                    link: downloadResources.ENTERPRISE_CLOUD_STUDIO_DOWNLOAD_LINK,
                };
            case 'TRIAL':
                return {
                    edition: 'Enterprise Trial',
                    link: downloadResources.ENTERPRISE_TRIAL_STUDIO_DOWNLOAD_LINK,
                };
            case 'COMMUNITY':
            default:
                return {
                    edition: 'Community Edition',
                    link: downloadResources.COMMUNITY_STUDIO_DOWNLOAD_LINK,
                };
        }
    }, [ accountTypeSelector ]);
    return (
        <Button
            className={classes.buttonDefault}
            variant="outlined"
            href={downloadResources.getDownloadURL(studioEditionAndLink.link)}
            onClick={async () => {
                await logStudioDownload(studioEditionAndLink.edition, StudioDownload.RightNav);
                updateMarketo(studioEditionAndLink.edition);
            }}
            data-cy="home-page-sidebar-download-studio-button"
        >
            {translate({ id: 'CLIENT_DOWNLOAD' })}
        </Button>
    );
};
