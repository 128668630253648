import { GlobalStyles } from '@experiences/theme';
import {
    Button,
    Card,
    CardContent,
    Link,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';

import { useDocumentationLinks } from '../../common/hooks/useDocumentationLink';
import { ReactComponent as EnterpriseStudioIcon } from '../../images/enterpriseStudio.svg';
import { ReactComponent as VerifiedIcon } from '../../images/verified.svg';
import { UiPanel } from '../common/UiPanel/UiPanel';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        root: {
            display: 'flex',
            height: '100%',
        },
        pageSubHeader: {
            fontWeight: 600,
            fontSize: '16px',
            color: theme.palette.semantic.colorForeground,
            marginBottom: '4px',
        },
        content: {
            display: 'flex',
            margin: '24px 0px 32px 0px',
        },
        svgIcon: {
            alignSelf: 'flex-start',
            display: 'flex',
            marginRight: '12px',
            '& .iconOrange': { stroke: theme.palette.semantic.colorForegroundHigh },
            '& .iconStar': { stroke: theme.palette.semantic.colorForegroundEmp },
            '& .iconLines': { fill: theme.palette.semantic.colorForeground },
            '& .iconOrangeFill': { fill: theme.palette.semantic.colorForegroundHigh },
            '& .iconBackground': { fill: theme.palette.semantic.colorBackgroundGray },
        },
        cardContent: { padding: '28px 22px 22px 28px' },
        enterpriseCard: {
            borderColor: theme.palette.semantic.colorBorderDeEmp,
            borderLeftWidth: '3px',
            borderLeftColor: theme.palette.semantic.colorLogo,
            borderRadius: '0px 3px 3px 0px',
            flex: '0 1 604px',
        },
        automationSuiteLink: { marginTop: '8px' },
        buttonDefault: {
            fontWeight: 600,
            textTransform: 'none',
            height: '32px',
            '& span': { height: '20px' },
        },
        downloadButton: { minWidth: '160px' },
        downloadButtonSecondary: { marginLeft: '12px' },
        downloadAndCopyStudio: { marginTop: '16px' },
        subtitle: { fontWeight: 500 },
    }),
}));

const ResourceCenter = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const getLocalizedLink = useDocumentationLinks();
    return (
        <UiPanel>
            <div>
                <Typography className={classes.pageSubHeader}>
                    {translate({ id: 'CLIENT_DOWNLOAD_UIPATH_STUDIO' })}
                </Typography>
            </div>
            <div
                className={classes.content}
                data-cy="enterprise-studio-card">
                <Card
                    variant="outlined"
                    className={classes.enterpriseCard}>
                    <CardContent className={classes.cardContent}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}>
                            <EnterpriseStudioIcon className={classes.svgIcon} />
                            <Typography
                                className={classes.pageSubHeader}
                                style={{ marginBottom: '16px' }}>
                                {translate({ id: 'CLIENT_ENTERPRISE_EDITION' })}
                            </Typography>
                            <div style={{ flexGrow: 1 }} />
                            <VerifiedIcon
                                className={classes.svgIcon}
                                style={{ marginRight: '0px' }} />
                        </div>
                        <ul>
                            <li>
                                <Typography
                                    className={classes.subtitle}
                                    display="inline">
                                    {translate({ id: 'CLIENT_ENTERPRISE_DESCRIPTION_FIRST_POINT' })}
                                </Typography>
                            </li>
                            <li>
                                <Typography
                                    className={classes.subtitle}
                                    display="inline">
                                    {translate({ id: 'CLIENT_ENTERPRISE_DESCRIPTION_SECOND_POINT' })}
                                </Typography>
                            </li>
                        </ul>
                        <div className={classes.downloadAndCopyStudio}>
                            <Button
                                href="https://download.uipath.com/UiPathStudio-x86.msi"
                                rel="noopener noreferrer"
                                variant="contained"
                                className={clsx(classes.buttonDefault, classes.downloadButton)}
                                data-cy="DownloadStudioButton"
                            >
                                {translate({ id: `CLIENT_x86_DOWNLOAD` })}
                            </Button>
                            <Button
                                href="https://download.uipath.com/UiPathStudio.msi"
                                rel="noopener noreferrer"
                                variant="contained"
                                className={clsx(classes.buttonDefault, classes.downloadButtonSecondary)}
                                data-cy="DownloadStudioButton"
                            >
                                {translate({ id: `CLIENT_x64_DOWNLOAD` })}
                            </Button>
                        </div>
                        <div className={classes.automationSuiteLink}>
                            <Link
                                href={getLocalizedLink()}
                                target="_blank"
                                rel="noopener noreferrer"
                                data-cy="automation-suite"
                            >
                                <Typography
                                    className={classes.subtitle}
                                    display="inline">
                                    {translate({ id: 'CLIENT_AUTOMATION_SUITE_DOC' })}
                                </Typography>
                            </Link>
                        </div>
                    </CardContent>
                </Card>
            </div>
        </UiPanel>
    );
};
export default ResourceCenter;
