import {
    AccountLicense,
    TelemetryTitle,
} from '@experiences/constants';
import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    Features,
    getFeatureFlagValue,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditIcon from '@mui/icons-material/Edit';
import {
    Tooltip,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    FormattedDate,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import type { Row } from 'react-table';

import { notificationType } from '../../common/constants/Constant';
import { AboutAccountsLink } from '../../common/constants/documentation/DocumentationLinks.default';
import * as RouteNames from '../../common/constants/RouteNames';
import useSimpleGroup from '../../common/hooks/SimpleGroup';
import useCheckLicense from '../../common/hooks/useCheckLicense';
import { useDocumentationLinks } from '../../common/hooks/useDocumentationLink';
import type { IRobot } from '../../common/interfaces/cis/robot';
import {
    deleteRobots,
    getRobots,
    robotUrl,
} from '../../services/identity/RobotAccountService';
import {
    accountGlobalId,
    isAdminSelector,
} from '../../store/selectors';
import type {
    IAction,
    IActionHeader,
} from '../common/UiGrid/grid';
import {
    ButtonType,
    GridActionType,
} from '../common/UiGrid/grid';
import { UiPaginatedGrid } from '../common/UiPaginatedGrid';
import UpgradeForFeature from '../common/UpgradeForFeature';

const EnableUnattRobotDisplayName = getFeatureFlagValue(Features.EnableUnattRobotDisplayName.name);

export interface IRobotResponse {
    totalCount: number;
    results: IRobot[];
}

const useStyles = makeStyles(theme =>
    createStyles({
        nameCell: { display: 'flex' },
        userRoleLabel: {
            fontSize: '11px',
            fontWeight: 'bold',
            color: theme.palette.semantic.colorForegroundHigh,
            marginLeft: '8px',
            backgroundColor: theme.palette.semantic.colorBackgroundHigh,
            height: '18px',
            marginTop: '1px',
        },
        servicesListCell: { maxWidth: '200px' },
        azureDescription: {
            marginTop: '8px',
            marginBottom: '8px',
        },
        addRobotAccount: { marginLeft: '5px' },
    }),
);

const RobotAccountPageComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const createDialog = useShowDialog();
    const { includesLicense } = useCheckLicense();

    const { getErrorMessage } = useGetErrorInfo();

    const history = useHistory();
    const getRoute = useRouteResolver();
    const getLocalizedLink = useDocumentationLinks();

    const isAdmin = useSelector(isAdminSelector);
    const currentAccountGlobalId = useSelector(accountGlobalId);

    const EnableOffersRevampAdmin = useFeatureFlagValue(Features.EnableOffersRevampAdmin.name);

    const {
        loading: groupLoading, getGroupsForUser,
    } = useSimpleGroup(true);

    const [ refresh, setRefresh ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    const setErrorMessage = useCentralErrorSetter();

    useEffect(() => {
        setLoading(groupLoading);
    }, [ groupLoading ]);

    useEffect(() => {
        history.listen(history => {
            if (history.state && (history.state as any)['refresh']) {
                setRefresh(true);
            }
        });
    }, [ history ]);

    const deleteRobotsAsync = useCallback(
        async (robots: IRobot[]) => {
            try {
                if (robots.length > 0) {
                    const robotIds = robots.map(robot => robot.id);
                    await deleteRobots(currentAccountGlobalId, robotIds);

                    setRefresh(true);
                    enqueueSnackbar(translate({ id: 'CLIENT_ROBOT_DELETED' }), { variant: notificationType.INPROGRESS as any });
                }
            } catch (error) {
                setErrorMessage(await getErrorMessage(error));
            }
        },
        [ enqueueSnackbar, translate, setErrorMessage, currentAccountGlobalId, getErrorMessage ],
    );

    const openDeleteDialog = useCallback(
        async (rows: Array<Row<IRobot>> | Row<IRobot>) => {
            const robots: IRobot[] = Array.isArray(rows) ? rows.map(row => row.original) : [ rows.original ];

            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_DELETE_ROBOT' }),
                body: (
                    <div>
                        <Typography>
                            {translate({ id: 'CLIENT_DELETE_ROBOT_ACCOUNT_WARNING' })}
                        </Typography>
                        <br />
                        <Typography>
                            {translate({ id: 'CLIENT_DELETE_ROBOT_ACCOUNT_CONFIRM_MESSAGE' })}
                        </Typography>
                    </div>
                ),
                icon: 'error',
                showCancel: true,
                primaryButtonText: translate({ id: 'CLIENT_DELETE' }),
            });
            if (proceed) {
                deleteRobotsAsync(robots);
            }
        },
        [ translate, createDialog, deleteRobotsAsync ],
    );

    const getRowActions = useCallback(() => {
        const actions: Array<IAction<IRobot>> = [];
        if (isAdmin) {
            actions.push({
                type: ButtonType.Icon,
                label: translate({ id: 'CLIENT_EDIT' }),
                tooltip: translate({ id: 'CLIENT_EDIT' }),
                actionType: GridActionType.Row,
                icon: <EditIcon />,
                click: row => {
                    history.push({
                        pathname: `${getRoute(RouteNames.Robots)}/edit`,
                        state: { robot: row.original },
                    });
                },
            });
            actions.push({
                type: ButtonType.Icon,
                label: translate({ id: 'CLIENT_DELETE' }),
                tooltip: translate({ id: 'CLIENT_DELETE' }),
                actionType: GridActionType.Row,
                icon: <DeleteForeverOutlinedIcon />,
                click: openDeleteDialog,
            });
        }
        return actions;
    }, [ getRoute, history, isAdmin, openDeleteDialog, translate ]);

    const extraActionHeaderButtons: IActionHeader[] = useMemo(() => {
        const actionList: IActionHeader[] = [];

        const addGroups: IActionHeader = {
            type: ButtonType.ButtonWithIcon,
            label: translate({ id: 'CLIENT_ADD_ROBOT_ACCOUNT' }),
            icon: <AddIcon />,
            click: () => history.push(`${getRoute(RouteNames.Robots)}/add`),
            invisible: !isAdmin,
            variant: 'contained',
            className: classes.addRobotAccount,
            dataCy: 'ui-grid-add-robot-button',
        };
        actionList.push(addGroups);
        return actionList;
    }, [ getRoute, history, isAdmin, translate, classes.addRobotAccount ]);

    return (
        <>
            {includesLicense([ AccountLicense.FREEKIT ]) && EnableOffersRevampAdmin ?
                <UpgradeForFeature
                    upgradeTitle={translate({ id: 'CLIENT_UPGRADE_ROBOT_TITLE' })}
                    upgradeMessage={translate({ id: 'CLIENT_UPGRADE_ROBOT_DESCRIPTION' })}
                    documentationLink={getLocalizedLink({ articleSlug: AboutAccountsLink })}
                    level={AccountLicense.PRO}
                    telemetryTitle={TelemetryTitle.RobotAccounts} />
                :
                <UiPaginatedGrid<IRobot, IRobotResponse>
                    url={robotUrl}
                    fetcher={getRobots}
                    fetcherArgs={[ currentAccountGlobalId ]}
                    data-cy="robots-ui-grid"
                    loading={loading}
                    search
                    searchPlaceholder={translate({ id: 'CLIENT_SEARCH_USER_TEXT' })}
                    extraActionButtons={extraActionHeaderButtons}
                    refreshData={{
                        current: refresh,
                        set: setRefresh,
                    }}
                    filters
                    checkbox={isAdmin}
                    columns={[
                        ...(EnableUnattRobotDisplayName
                            ? [
                                {
                                    accessor: 'displayName',
                                    sortName: 'DisplayName',
                                    Header: translate({ id: 'CLIENT_DISPLAY_NAME' }),
                                    width: 20,
                                    Cell: ({ row }: any) => (
                                        <div className={clsx(classes.nameCell)}>
                                            <Tooltip
                                                arrow
                                                title={row.original.displayName}>
                                                <span>
                                                    {row.original.displayName}
                                                </span>
                                            </Tooltip>
                                        </div>
                                    ),
                                },
                            ]
                            : []),
                        {
                            accessor: 'name',
                            sortName: 'Name',
                            Header: translate({ id: 'CLIENT_NAME' }),
                            width: 20,
                            Cell: ({ row }) => (
                                <div className={clsx(classes.nameCell)}>
                                    <Tooltip
                                        arrow
                                        title={row.original.name}>
                                        <span>
                                            {row.original.name}
                                        </span>
                                    </Tooltip>
                                </div>
                            ),
                        },
                        {
                            accessor: 'groups',
                            Header: translate({ id: 'CLIENT_GROUPS' }),
                            width: 30,
                            disableSortBy: true,
                            Cell: ({ row }) => {
                                const groups = getGroupsForUser(row.original.groupIds)?.join(', ');
                                return groups ? (
                                    <div className={clsx(classes.servicesListCell)}>
                                        <Tooltip
                                            arrow
                                            placement="bottom-start"
                                            title={groups}>
                                            <span>
                                                {groups}
                                            </span>
                                        </Tooltip>
                                    </div>
                                ) : (
                                    <span>
                                        {translate({ id: 'CLIENT_NO_GROUPS_FOUND' })}
                                    </span>
                                );
                            },
                        },
                        {
                            accessor: 'lastLoginTime',
                            sortName: 'LastLoginTime',
                            Header: translate({ id: 'CLIENT_LAST_ACTIVE' }),
                            width: 15,
                            Cell: ({ row }) => {
                                const date =
                (row.original.lastLoginTime && new Date(row.original.lastLoginTime)) ||
                new Date(row.original.creationTime);
                                return <FormattedDate
                                    value={date}
                                    year="numeric"
                                    month="short"
                                    day="numeric" />;
                            },
                        },
                    ]}
                    tableActions={
                        isAdmin
                            ? [
                                {
                                    type: ButtonType.Button,
                                    label: translate({ id: 'CLIENT_DELETE' }),
                                    icon: <DeleteForeverOutlinedIcon />,
                                    actionType: GridActionType.Selected,
                                    click: openDeleteDialog,
                                },
                            ]
                            : []
                    }
                    rowActions={getRowActions()}
                />}
        </>
    );
};

export default RobotAccountPageComponent;
